@import '../../../../vars';


.total-payment {
  margin: 35px 0;
  padding: 20px 20px;
  background: $lightest-grey-color;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .amount, .account {
    font-size: 20px;
    font-weight: normal;
    color: $black;
    margin-bottom: 8px;
  }

  .bank-account {
    display: flex;
    justify-content: flex-end;

    a {
      padding-top: 5px;
      font-weight: bold;
    }
  }
}
