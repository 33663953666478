@import '../../../../vars';

.dashboard {
  min-height: 100vh;

  &__aside {
    overflow: hidden;
    height: 100vh;
    position: fixed;
    left: 0;

    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .ant-menu {
      margin-top: 30px;
    }
  }

  &__logo {
    position: relative;

    svg {
      max-width: 150px;
    }
    img {
      width: 100px;
    }
  }

  &__logo,
  &__bottom {
    padding: 36px;
  }

  &__main {
    margin-left: 256px;
    position: relative;
    padding-bottom: $footer-height;
  }

  &__content {
    margin: 76px 72px 0;
    overflow: initial;
  }
}
