@import '../../../../../vars';


$collapse-left-padding: 50px;

.welcome-guide {
  .title {
    margin-bottom: 40px;
  }

  &__collapse {
    background: $white;
    font-size: 16px;
    width: 750px;

    :global .ant-collapse-item {
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      padding: 4px 14px;

      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }

      &.ant-collapse-item-active {
        .ant-collapse-header {
          font-weight: bold;
        }
      }

      .ant-collapse-header {
        color: $black;
        padding-left: $collapse-left-padding;

        .ant-collapse-arrow svg {
          stroke: $black;
          stroke-width: 60px;
          width: 1.1em;
          height: 1.1em;
        }

        .ant-collapse-extra svg {
          fill: $border-color;
        }
      }

      .ant-collapse-content-box {
        padding: 0 10px 20px $collapse-left-padding;

        > p {
          color: $body-color;
          padding-right: 50px;
        }

        > div {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
        }
      }
    }
  }
}
