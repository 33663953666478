.regenerate-backup-wrapper {
  display: flex;

  > div:nth-child(2) {
    flex: 1;
    height: unset;
  }
}

.regenerate-backup-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  header {
    width: 460px;
    text-align: center;

    p {
      font-size: 16px;
    }
  }

  > div, form {
    width: 340px;
  }
}
