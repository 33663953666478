@import '../../../../vars';


.total-payment {
  margin: 35px 0;
  padding: 20px 20px;
  background: $lightest-grey-color;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .highlight {
    font-size: 20px;
    font-weight: normal;
    color: $black;
    margin-bottom: 8px;
  }

  .payment-method {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 50%;

    a {
      padding-top: 5px;
      padding-left: 30px;
      font-weight: bold;
    }
  }
}
