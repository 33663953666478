@import '../../../../../vars';

.consent-modal {
  &__content-wrapper {
    .header {
      h2 {
        font-size: 22px;
        font-style: normal;
        font-weight: 350;
        line-height: normal;
        letter-spacing: -0.66px;
        margin-bottom: 4px;
      }

      p {
        color: #636B79;
        font-family: "Avenir Next";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 166%;
        letter-spacing: -0.42px;
      }

      span {
        text-decoration-line: underline;
      }
    }

    main {
      padding-top: 40px;
    }
    
    footer {
      display: flex;
      gap: 20px;

      .delete-btn {
        border: 1px solid #E84060;
        color: #E84060;
        margin-left: auto;
      }

      :global .ant-btn {
        padding: 10px 32px;
      }
    }
  }

  :global .ant-form-item-label {
    & > label {
      font-size: 16px;
      font-family: "Avenir Next";
    }

    & > label.ant-form-item-required {
      &:before {
        content: unset;
      }

      &:after {
        display: inline-block;
        margin-left: 4px;
        color: #ff4d4f;
        font-size: 14px;
        line-height: 1;
        content: '*';
      }
    }
  }

  :global .ant-form-item {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 40px;

    .ant-form-item-label {
      display: flex;
      align-items: flex-start;
    }
    .ant-form-item-control {
      flex: 0 0 400px;
      flex-grow: unset;
    }
  }
}
