@import '../../../../../vars';

:global .StripeElement {
  height: 40px;
  padding: 10px 0;
  width: 100%;
  color: #32325d;
  background-color: white;
  border-bottom: 1px solid #B3B9C4;
  margin-bottom: 10px;


  &--invalid {
    border-color: $badge-danger;
  }

  &--focus {
    border-color: $input-border-focus;
  }

  &--webkit-autofill {
    background-color: $lightest-grey-color !important;
  }

}

.new-payment-card-section {
  width: 420px;
  padding: 40px;
  background: $white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
  margin: 50px 0;

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__field {
    width: 100%;

    & + .new-payment-card-section__field {
      margin-left: 100px;
    }

    label {
      font-size: 12px;
      color: $body-color;
    }

    :global .ant-input {
      padding: 10px 0;
    }
  }
}

.new-payment-card-form {
  :global .ant-btn {
    width: 140px;
  }
}
