.set-new-password-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 340px;
  height: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-bottom: 20%;
  }

  &__footer {
    padding-top: 40px;
    padding-bottom: 46px;
  }
}
