.deactivation-modal {
  &__title {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 16px;
  }
  .ant-modal-footer {
    text-align: left;
    display: flex;
    & > div {
      display: flex;
      flex-direction: row-reverse;
    }
  }
}
