@import '../../../../vars';

.badge {
  display: inline-flex;

  &__icon {
    position: relative;
    border-radius: 50%;
    background-color: $white;
    width: 20px;
    height: 20px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);

    &--margin {
      margin-right: 12px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      width: 12px;
      height: 12px;
      border-radius: 50px;
      background-color: $badge-default;
    }
  }

  &--info {
    .badge__icon:after{
      background-color: $badge-info;
    }
  }

  &--success {
    .badge__icon:after{
      background-color: $badge-success;
    }
  }

  &--danger {
    .badge__icon:after{
      background-color: $badge-danger;
    }
  }

  &--warning {
    .badge__icon:after{
      background-color: $badge-warning;
    }
  }

  &--disabled {
    .badge__icon:after{
      background-color: $light-grey-color;
    }
  }

  &--warning {
    .badge__icon:after{
      background-color: $badge-warning;
    }
  }

  &__label {
    color: $body-color;
    font-size: 14px;
    line-height: 21px;
  }
}
