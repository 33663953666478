@import '../../../../vars';

.profile-wrapper {
  display: flex;

  > div:nth-child(2) {
    flex: 1;
    height: unset;
  }
}

.profile-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 340px;
  height: 100%;
  margin: 0 auto;
  text-align: center;
}

.actions {
  display: flex;
  flex-direction: column;
  text-align: left;

  margin-top: 60px;
  margin-bottom: 60px;
  font-size: 16px;

  hr {
    width: 100%;
    border: none;
    border-bottom: 1px solid #efefef;
    margin: 16px 0;
  }

  span {
    font-size: 12px;

    a {
      color: unset;
      text-decoration: underline;
    }
  }

  .info {
    color: $black;
  }

  .enabled {
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: $black;
    border: 1px solid $line-color;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 16px;
    margin: 16px 0;

    svg {
      height: 12px;
      width: 12px;
      cursor: pointer;

      path {
        fill: $border-color;
      }
    }
  }
}
