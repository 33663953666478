@import '../../../../../vars';

.super-app-form {
  display: flex;

  &-wrapper {
    width: 80%;
  }

  &__section {
    &:first-child, &:last-child {
      margin-top: 70px;
    }
    margin-top: 80px;
    width: 670px;
  }

  &__save-btn-wrapper {
    display: flex;
    padding-top: 70px;
    flex-grow: 1;
    flex-direction: row-reverse;

    > button {
      position: -webkit-sticky !important; /* Safari */
      position: sticky !important;
      top: 70px;
    }
  }

  .integration-text {
    a {
      font-weight: bold;
    }
  }

  .pass-colors-label {
    display: flex;
    align-items: center;

    > svg {
      margin-left: 12px;
      margin-bottom: 2px;
    }
  }

  .consent-model-item {
    :global .ant-form-item-control-input-content {
      display: flex;
      &::before {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-right: 4px;
        content: '•';
        color: #B3B9C4;
      }
    }
  }

  :global .ant-form-item-label {
    & > label {
      font-size: 16px;
    }

    & > label.ant-form-item-required {
      &:before {
        content: unset;
      }

      &:after {
        display: inline-block;
        margin-left: 4px;
        color: #ff4d4f;
        font-family: SimSun, sans-serif;
        font-size: 14px;
        line-height: 1;
        content: '*';
      }
    }
  }

  :global .ant-form-item {
    justify-content: space-between;

    .ant-form-item-label {
      display: flex;
      align-items: flex-start;
    }
    .ant-form-item-control {
      flex: 0 0 400px;
      flex-grow: unset;
    }
  }

  &__share-colors {
    :global .ant-form-item .ant-form-item-control {
      flex: 0 0 385px;
    }
  }
}
