@import '../../../../../vars';

.miniapp-settings {
  width: 80%;

  .white-listed-urls-select {
    :global {
      .ant-select-selector {
        font-size: 16px;
        padding-right: 35px;
      }
    }
  }

  .mini-app-form {
    display: flex;

    &__section {
      &:first-child, &:last-child {
        margin-top: 70px;
      }
      margin-top: 80px;
      width: 670px;
    }

    &__save-btn-wrapper {
      display: flex;
      padding-top: 70px;
      flex-grow: 1;
      flex-direction: row-reverse;

      > button {
        position: -webkit-sticky !important; /* Safari */
        position: sticky !important;
        top: 70px;
      }
    }

    &__description-text {
      font-size: 16px;
      color: $body-color;
      margin-bottom: 24px
    }
  }

  .color-input-wrapper {
    :global .ant-form-item .ant-form-item-control {
      flex: 0 0 385px;
    }
  }

  :global .ant-form-item-label {
    & > label {
      font-size: 16px;
    }

    & > label.ant-form-item-required {
      &:before {
        content: unset;
      }

      &:after {
        display: inline-block;
        margin-left: 4px;
        color: #ff4d4f;
        font-family: SimSun, sans-serif;
        font-size: 14px;
        line-height: 1;
        content: '*';
      }
    }
  }

  :global .ant-form-item {
    justify-content: space-between;

    .ant-form-item-label {
      display: flex;
      align-items: flex-start;
    }
    .ant-form-item-control {
      flex: 0 0 400px;
      flex-grow: unset;
    }
  }

  :global .ant-radio-wrapper {
    width: 120px;
  }

  .label-tooltip {
    display: flex;
    align-items: center;

    > svg {
      margin-left: 12px;
      margin-bottom: 2px;
    }
  }

  .white-listed-select-tag {
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.orientation-warning-modal {
  &__content {
    color: $black;
    font-size: 16px;
    margin-bottom: 40px;
    line-height: 166.64%;
  }

  &__buttons-wrapper {
    display: flex;
    align-items: center;

    button {
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

:global {
  .white-listed-select-tooltip {
    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-content {
      background: $lightest-grey-color;
      width: 340px;

      .ant-tooltip-inner {
        display: flex;
        align-items: center;

        max-width: 340px;
        padding: 4px 12px;
        border-radius: 4px;
        border: 1px solid $line-color;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);

        color: $dark;
        font-size: 12px;
        line-height: 166.64%;
        word-break: break-all;
        background: $lightest-grey-color;
      }
    }
  }
}

