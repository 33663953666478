.team {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__table {
    padding-top: 20px;
  }
}
