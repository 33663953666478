@import "../../../../vars";

.role-form {

  &__email {
    width: 50%;
    padding-bottom: 20px;
  }

  &__roles {
    padding-bottom: 20px;
  }

  &__role-item {
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__role-text {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    white-space: pre-wrap;
  }

  &__role-title {
    color: $black;
    font-weight: bold;
  }

  &__radio-group {
    width: 100%;
  }

  &__errors {
    padding: 0 0 40px;
  }

  &__error {
    text-align: center;
    color: $danger-color;
  }
}
