@import '../../../../../../../../vars';

.error-placeholder {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    text-align: center;

    .icon {
      fill: $badge-default;
      margin-bottom: 13px;
    }

    .description {
      font-size: 12px;
      color: $body-color;
      line-height: 166.64%;
    }
  }
}
