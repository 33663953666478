@import '../../../../vars';

.app-details {
  display: flex;
  justify-content: space-between;

  aside {
    width: 320px;
    margin-right: 60px;

    .integrate-btn {
      position: relative;
      z-index: 1;

      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
      background: $white;

      border-radius: 10px;
      margin-top: 1px;
      padding: 20px;

      &__btn {
        width: 100%;
      }
    }
  }

  main {
    position: relative;
    width: calc(100% - 380px);
  }

  &__tabs {
    margin-bottom: 40px;

    h4 {
      font-size: 18px;
    }

    :global {
      .ant-tabs-bar {
        margin-bottom: 0;
      }
    }
  }

  h3.ant-typography:first-of-type {
    margin-top: 0;
  }

  h3.ant-typography {
    margin-top: 1.2em;
  }
}
