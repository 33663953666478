@import '../../../../vars';

.wire-transfer-desc {
  color: $black;
}

.wire-transfer-grid {
  display: grid;
  grid-template-columns: 150px 250px;
  justify-content: space-between;
  row-gap: 10px;

  margin-top: 20px;
  border: 1px solid $line-color;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 20px;

  div:nth-child(2n) {
    color: $black
  }
}
