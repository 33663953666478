@import '../../../../../vars';

.analytics-overall-table {
  width: 100%;
  margin-bottom: 40px;

  th {
    padding: 20px 0 12px;
    border-bottom: 1px solid #efefef;
    color: $body-color;
    font-weight: normal;
    text-align: right;

    width: 15%;
    &:nth-of-type(1) {
      text-align: left;
      width: 25%;
    }
  }

  td {
    color: $black;
    padding: 20px 0;
    text-align: right;

    &:nth-of-type(1) {
      text-align: left;
    }
  }
}
