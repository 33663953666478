.profile-nav {
  position: fixed;

  :global {
    .ant-dropdown-menu {
      border-radius: 4px;

      .ant-dropdown-menu-item {
        padding: 12px;
      }
    }
  }

  &__trigger {
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      .name {
        width: 104px;
        overflow: hidden;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    :global {
      .ant-avatar {
        margin-right: 8px;
      }
    }
  }

  &__more-icon {

  }

  .active-link {
    font-weight: bold;
  }
}
