@import '../../../../vars';


.payment-methods {
  .header {
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
  }

  table {
    width: 100%;

    th {
      padding: 20px 0 12px;
      border-bottom: 1px solid $border-color;
      color: $body-color;
      font-weight: normal;

      &:nth-child(1) {
        width: 30%;
      }
    }

    td {
      padding: 20px 0;

      .highlight {
        font-weight: bold;
        color: $black;
        margin-bottom: 4px;
      }

      .default {
        margin-left: 8px;
        background: $black;
        color: $white;
        padding: 3px 6px;
        border-radius: 4px;
      }
    }
  }
}
