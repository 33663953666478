@import '../../../../../node_modules/cropperjs/dist/cropper.css';

.logo-cropper {
  .ant-modal-content {
    .ant-modal-body {
      padding: 0;
    }

    .ant-modal-footer {
      padding-top: 20px;
    }
  }
}
