.billing-info {
  .header {
    display: flex;
    justify-content: space-between;

    .details {
      display: flex;

      .badge {
        margin-left: 10px;
        padding-top: 10px;
      }
    }

    .actions {
      color: #E5E5EB;
    }
  }
}
