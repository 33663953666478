@import '../../../vars';

.showroom {
  &__filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__search {
    width: 340px;
    margin-right: 30px;
  }

  &__select {
    min-width: 220px;
    margin-left: 40px;
  }

  &__loader {
    margin-top: 94px;
  }

  &__apps {
    margin-top: 40px;

    .search-results-title {
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 20px;
      letter-spacing: -0.03em;
    }

    .cards {
      display: grid;
      grid-gap: 40px;
      grid-template-columns: repeat(auto-fill, 320px);

      .card {
        margin-bottom: 66px;
      }
    }
  }
}
