@font-face {
	font-family: 'SF Pro';
	src: url('./assets/fonts/SF-Pro.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'SF Pro';
	src: url('./assets/fonts/SF-Pro-Text-Light.otf') format('opentype');
	font-weight: 400;
	font-style: normal;
}

@import './vars';

h2 {
  font-weight: 400;
}

body {
  overflow-y: scroll;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

// Globals classnames
.text-center {
  text-align: center;
}

.full-page-pane {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  z-index: 10;
  background-color: $white;
}

.form-layout {
  max-width: 340px;
  margin: 0 auto;
  height: 100%;
  width: 100%;

  &__form-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    text-align: center;
  }
}

.cards-row {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));

  & + .cards-row {
    margin-top: 80px;
  }
}

.m-bottom-0 {
  margin-bottom: 0;
}

// Ant design overrides

.ant-input,
.ant-select-selection {
  border-bottom: 1px solid $border-color;
  border-top-width: 0;
}

.ant-input-affix-wrapper,
.ant-input-prefix-wrapper,
.ant-select.ant-select-single .ant-select-selector,
.ant-select.ant-select-multiple .ant-select-selector{
  border-bottom-width: 1px;
}

.ant-select-selector {
  border-bottom-color: $border-color !important;
}
// Fix displaying text cursor in multiple select input
.ant-select-selection-search-input {
  padding: 0;
}

.ant-checkbox-inner {
  border-width: 1px;
}

.ant-form-explain {
  margin-top: 2px;
}

h1.ant-typography, .ant-typography h1,
h2.ant-typography, .ant-typography h2,
h4.ant-typography, .ant-typography h4,
h3.ant-typography, .ant-typography h3 {
  font-weight: 400;
}

h3.ant-typography, .ant-typography h3 {
  font-size: 22px;
}

.ant-menu-inline .ant-menu-item::after {
  display: none;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  font-weight: bold;
}

.ant-card {
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);

  .ant-card-head-title {
    font-size: 22px;
    padding: 40px 0  10px;
  }

  .ant-card-body {
    padding: 0 24px 24px;
  }
}

.ant-btn {
  font-weight: bold;
}

.ant-btn-sm {
  height: 36px;
  padding: 0 32px;
  font-size: 14px;
  border-radius: 6px;
}

.confirmation-modal {
  .ant-btn {
    width: 120px;
  }
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: $dark;
}

div.ant-typography {
  white-space: pre-wrap;
}

.ant-dropdown-trigger.anticon-ellipsis {
  font-size: 18px;
}

// Tabs style override
.ant-tabs-top-bar {
  border-bottom: 1px solid $line-color;

  .ant-tabs-tab-active {
    font-weight: bold;
    color: $black;
  }

  .ant-tabs-tab {
    font-size: 16px;

    &:hover {
      color: $black;
    }
  }

  .ant-tabs-ink-bar {
    background-color: $black;
  }
}

.ant-form-vertical .ant-form-item-label {
  padding-bottom: 0;
}

.ant-form-item-label > label {
  color: $body-color;
}

.ant-modal-content {
  .ant-modal-title {
    font-size: 22px;
    color: $black;
  }
  .ant-modal-close {
    padding: 20px;
  }

  border-radius: 10px;
  .ant-modal-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 40px 40px 0 40px;
  }

  .ant-modal-body {
    padding: 40px;
  }

  .ant-modal-footer {
    padding: 0 40px 40px 40px;
  }
}

.ant-select-dropdown {
  .ant-select-item-option-selected {
    font-weight: normal;
    background: unset;
  }
}

.ant-tooltip-arrow:before {
  background-color: $white;
}

.ant-tooltip-inner {
  padding: 14px;
  border-radius: 2px;
  background-color: $white;
  color: $body-color;
}

th {
  text-align: left;
}

.btn-link {
  padding: 0;
  height: min-content;
}

.btn-outline {
  color: $primary-color;
  border: 1px solid $primary-color;
}

.copy-icon {
  fill: $primary-color;
  cursor: pointer;
}
