.disable-2fa-wrapper {
  display: flex;

  > div:nth-child(2) {
    flex: 1;
    height: unset;
  }
}

.two-factor-auth-disable {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  header {
    width: 460px;
    text-align: center;

    p {
      font-size: 16px;
    }
  }

  > div, form {
    width: 340px;
    margin: 0 auto;
  }

  .helper-text {
    font-size: 12px;

    a {
      color: unset;
      text-decoration: underline;
    }
  }
}
