@import '../../../../vars';

.payment-card {
  width: 162px;
  padding: 20px;
  display: inline-flex;
  justify-content: space-between;
  background: $white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
  align-items: center;
  margin-bottom: 12px;
  margin-right: 12px;
  cursor: pointer;
  position: relative;

  &--large {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
    width: 400px;
    height: 180px;
    padding: 30px;

    .payment-card__icon {
      width: 100%;
      justify-content: flex-end;

      svg {
        width: 60px;
      }
    }

    .payment-card__number {
      font-size: 32px
    }

    &.payment-card--new {
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .payment-card__number {
        font-size: 16px;
      }

      .payment-card__icon {
        width: auto;

        svg {
          width: 60px;
        }
      }
    }
  }

  .star-small {
    position: absolute;
    top: 2px;
    left: 5px;
  }

  &--new {
    background-color: $lightest-grey-color;
    box-shadow: none;
  }

  &__icon {
    display: flex;

    svg {
      width: 28px;
    }
  }

  &__number {
    font-size: 16px;
  }
}
