.category-details {
  .title {
    line-height: 44px;
    letter-spacing: -0.03em;
  }

  .search {
    width: 335px;
    margin: 40px 0;
  }

  .cards {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(auto-fill, 320px);
  }
}
