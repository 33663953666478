@import '../../../../vars';

.tooltip {
  :global {
    .ant-tooltip-content {
      .ant-tooltip-inner {
        padding: 2px 8px 2px 16px;
        display: flex;
        align-items: center;

        div {
          max-height: 300px;
          overflow-y: auto;
          padding: 10px 8px 10px 0;
        }
      }
    }
  }
}

.country-tooltip {
  :global {
    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-inner {
      display: flex;
      align-items: center;

      padding: 4px 12px;
      border-radius: 14px;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);

      color: $dark;
      font-size: 12px;
      background: $white;
      line-height: 166.64%;
    }
  }
}

.card {
  display: grid;
  grid-template-rows: 298px 145px 1px 71px;
  border-radius: 10px;
  transition: ease-in-out 150ms;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
  padding: 0 20px;

  &.hover {
    cursor: pointer;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15);
    }
  }

  .logo {
    border-radius: 30px;
    overflow: hidden;
    height: 160px;
    width: 160px;
    margin: auto;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;

    h2 {
      margin: 0;
      font-size: 22px;
      line-height: 30px;
      letter-spacing: -0.03em;
    }

    .category {
      margin: 0;
      font-size: 12px;
      color: $body-color;
      line-height: 166.64%;
    }

    .description {
      color: $black;
      font-size: 16px;
      margin: 16px 0 0;
      line-height: 166.64%;
    }
  }

  .divider {
    height: 1px;
    width: 100%;
    background: $line-color;
  }

  .countries {
    display: flex;
    align-items: center;

    &__flag {
      width: 24px;
      height: 24px;
      margin-right: 8px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    &__worldwide {
      display: flex;
      align-items: center;
      color: $black;
      font-size: 12px;

      .icon {
        fill: $primary-color;
        margin-right: 10px;
      }
    }

    &__counter {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 25px;
      padding: 0 5.5px;
      border-radius: 50%;
      border: 1px solid #E5E5EB;
      color: $black;
      font-size: 10px;
      font-weight: 500;
      line-height: 166%;
    }

    &__empty {
      display: flex;
      align-items: center;

      color: $black;
      font-size: 12px;

      .icon {
        fill: #CCD0E2;
        margin-right: 10px;
      }
    }
  }
}
