@import '../../../../../vars';

.new-mini-app-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 340px;
  margin: 0 auto;
  justify-content: space-between;
  text-align: center;

  &__terms {
    text-align: left;
    color: $dark-grey-color;
    font-size: 13px;

    a {
      color: $dark-grey-color;
      text-decoration: underline;
    }
  }
}
