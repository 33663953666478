@import '../../../../vars';

.countries-filter {
  color: $primary-color;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;

  .arrow-down {
    margin-left: 15px;
    fill: $primary-color;
  }

}
