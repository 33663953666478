@import '../../../../../vars';

.mini-app-form {
  display: flex;

  .label-tooltip {
    display: flex;
    align-items: center;

    > svg {
      margin-left: 12px;
      margin-bottom: 2px;
    }
  }

  &-wrapper {
    width: 80%;
  }

  &__section {
    &:first-child, &:last-child {
      margin-top: 70px;
    }
    margin-top: 80px;
    width: 670px;
  }

  &__save-btn-wrapper {
    display: flex;
    padding-top: 70px;
    flex-grow: 1;
    flex-direction: row-reverse;

    > button {
      position: -webkit-sticky !important; /* Safari */
      position: sticky !important;
      top: 70px;
    }
  }

  :global .ant-form-item-label {
    & > label {
      font-size: 16px;
    }

    & > label.ant-form-item-required {
      &:before {
        content: unset;
      }

      &:after {
        display: inline-block;
        margin-left: 4px;
        color: #ff4d4f;
        font-family: SimSun, sans-serif;
        font-size: 14px;
        line-height: 1;
        content: '*';
      }
    }
  }

  :global .ant-form-item {
    justify-content: space-between;

    .ant-form-item-label {
      display: flex;
      align-items: flex-start;
    }
    .ant-form-item-control {
      flex: 0 0 400px;
      flex-grow: unset;
    }
  }

  .long-textarea {
    :global .ant-form-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .ant-form-item-control {
        flex: 0 0;

        .ant-form-item-control-input-content {
          margin-top: 10px;
          border: 0.75px solid $border-color;
          box-sizing: border-box;
          border-radius: 2px;

          ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 4px;
          }

          ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: $line-color;
          }

          textarea {
            border: none;
            outline: none;
          }
        }
      }
    }
  }

  .commercial-model-item {
    :global .ant-form-item-control-input-content {
      display: flex;
      &::before {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-right: 4px;
        content: '•';
      }

      .ant-input {
        font-size: 16px;
      }

      .ant-input-suffix {
        display: flex;
        align-items: center;

        > span {
          width: 12px;
        }
      }
    }

    :global .ant-form-item-explain {
      padding-left: 10px;
    }
  }

  .hide-commercial-label {
    display: flex;
    align-items: center;

    > svg {
      margin-left: 12px;
      margin-bottom: 2px;
    }
  }
}

.form-item {
  &__title {
    line-height: 166.64%;
    font-size: 18px;
    color: $black;
  }

  &__description {
    font-size: 12px;
    max-width: 690px;
    color: $body-color;
    margin-bottom: 24px;
    line-height: 166.64%;
  }
}

.settings-link {
  cursor: pointer;
  color: $primary-color;
}
