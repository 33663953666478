@import "../../../../vars";

.filter-current-indicator {
  color: $primary-color;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;

  .arrow-down {
    margin-left: 15px;
    fill: $primary-color;
  }
}

.filter-item-active {
  color: $primary-color;
  font-weight: bold;
}

:global .filter-menu {
  margin-top: 24px;

  .ant-dropdown-menu {
    padding: 20px 0;
    border-radius: 5px;

    li {
      padding: 0 20px;
      font-size: 16px;
      line-height: 166.64%;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
