@import '../../../../../vars';

.app-type-nav {
  :global .ant-modal-content {
    padding: 40px;

    .ant-modal-title {
      text-align: center;
    }

    .ant-modal-body {
      padding-top: 35px;
    }
  }
  .app-types {
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      width: 350px;
      padding: 40px;
      border: 1px solid $line-color;
      box-sizing: border-box;
      border-radius: 10px;

      position: relative;
      top: 0;
      transition: top ease 0.5s;
      cursor: pointer;
      &:hover {
        top: -10px;
      }

      > * {
        margin-bottom: 14px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
