@import '../../../vars';


.billing-history, .payout-history {
  padding-top: 20px;

  .header {
    display: flex;
    justify-content: space-between;
  }

  table {
    width: 100%;

    th {
      padding: 20px 0 12px;
      border-bottom: 1px solid $border-color;
      color: $body-color;
      width: 16%;

      &:nth-child(1) {
        width: 20%;
      }

      &:nth-child(2) {
        width: 28%;
      }
    }

    tbody {
      tr:hover {
        cursor: pointer;
        background: $lightest-grey-color;
        border-radius: 10px;
      }

      td {
        &:first-child {
          padding-left: 10px;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
        &:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          padding-right: 10px;
          text-align: right;

          span {
            color: $black;
            font-size: 20px;
            vertical-align: middle;
          }
        }

        &:nth-child(3) {
          color: $black;
        }

        vertical-align: top;
        padding: 20px 0;
      }

      .amount {
        display: flex;
        color: $black;
        font-size: 18px;

        div {
          width: 54px;
          height: 54px;
          margin-right: 20px;
          div {
            width: 36px;
            height: 36px;
          }
        }

        > span {
          height: min-content;
        }
      }

      .payer {
        color: $black;
        font-weight: bold;
        margin-bottom: 5px;
      }

      .status-block {
        display: flex;
        flex-direction: column;

        > button {
          text-align: left;
          padding: 0 0 0 3px;
          height: min-content;
        }
      }
    }
  }
}
