@import '../../../../vars';

.date-picker-wrapper {
  margin: 40px 0;
  display: flex;
  justify-content: space-between;

  .separator {
    fill: $border-color;
  }

  .date-picker {
    margin-left: -11px;

    :global {
      .ant-picker-range-separator {
        padding: 0 20px;
      }

      .ant-picker-input {
        position: relative;

        > input {
          color: $black;
        }

        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -3px;

          width: 100%;
          height: 1px;
          background: $border-color;
        }
      }
    }
  }
}

.miniapp-metrics {
  &__date-click-area {
    cursor: pointer;
    font-size: 22px;
    line-height: 30px;
    width: max-content;
    margin-bottom: 40px;
    color: $primary-color;
    letter-spacing: -0.03em;

    .ant-select-selector {
      padding-left: 0 !important;
    }
  }

  table {
    width: 100%;
    text-align: left;
    table-layout: fixed;

    th {
      color: $dark-grey-color;
      font-weight: normal;
      text-align: right;
    }

    td {
      font-weight: bold;
      color: $black;
      text-align: right;
    }
  }

  hr {
    margin: 20px 0;
    border: none;
    border-bottom: 1px solid #efefef;
  }

  &__integration:hover {
    cursor: pointer;
    background: $lightest-grey-color;
    border-radius: 10px;
  }

  &__row {
    display: grid;
    grid-template-columns: 25% 1fr;
    padding: 20px 10px;

    .title {
      margin: 0;
      font-size: 16px;

      display: grid;
      grid-gap: 16px;
      align-items: center;
      grid-template-columns: 1fr;

      &.has-logo {
        grid-template-columns: 36px 1fr;
      }

      h4 {
        margin: 0;
        font-size: 16px;
      }

      img {
        width: 36px;
        height: 36px;
        border-radius: 5px;
        margin-right: 12px;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
      }
    }
  }
}
