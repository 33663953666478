@import '../../../../vars';

.partnership-details {
  .title {
    margin: 0 20px 0;

    i {
      color: $line-color;
    }
  }

  .actions {
    :global(.ant-btn) {
      padding: 10px 32px;
    }

    :global(.ant-btn-default) {
      border: 1px solid #222;
    }
  }

  .tabs {
    margin: 40px 0;
  }
}
