.wrapper {
  position: relative;
  width: 10px;
  height: 10px;

  .spinner {
    position: absolute;
    bottom: 35px;
    right: 35px;
    width: 100%;
    height: 100%;

    display: block;
  }

  .spinner div {
    transform-origin: 40px 40px;
    animation: spinner 1.2s linear infinite;
  }

  .spinner div span {
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #fff;
  }

  .spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1s;
  }

  .spinner div:nth-child(2) {
    transform: rotate(45deg);
    animation-delay: -0.975s;
  }

  .spinner div:nth-child(3) {
    transform: rotate(90deg);
    animation-delay: -0.75s;
  }

  .spinner div:nth-child(4) {
    transform: rotate(135deg);
    animation-delay: -0.625s;
  }

  .spinner div:nth-child(5) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }

  .spinner div:nth-child(6) {
    transform: rotate(225deg);
    animation-delay: -0.375s;
  }

  .spinner div:nth-child(7) {
    transform: rotate(270deg);
    animation-delay: -0.25s;
  }

  .spinner div:nth-child(8) {
    transform: rotate(315deg);
    animation-delay: -0.125s;
  }

  .spinner div:nth-child(9) {
    transform: rotate(360deg);
    animation-delay: 0s;
  }

  @keyframes spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

}