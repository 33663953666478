.result-pane {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  height: 100%;

  &__icon {
    position: relative;
    width: 92px;
    height: 92px;
    margin: 0 auto 40px;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__content {
    margin-top: 60px;
  }
}
