.wrapper {
  position: relative;
  user-select: none;
  height: 100%;
  width: 100%;

  .placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
  }

  img {
    position: relative;
    z-index: 2;
    opacity: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 1s;

    &.loaded {
      opacity: 1;
    }
  }
}
