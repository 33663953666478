@import "../../../vars";

.partnerships {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 40px;

    .search {
      width: 340px;
      margin-right: 30px;
    }
  }

  .cards {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(auto-fill, 500px);
  }

  .nodata-placeholder-wrapper {
    margin-top: 132px;
  }
}
