.super-app-details {
  .cards-row {
    margin-top: 40px;
    padding: 15px;
  }

  .no-active-miniapp {
    margin-top: 20px;
    text-align: center;
  }
}
