@import '../../../../../vars';

.StripeElement {
  height: 40px;
  padding: 10px 0;
  width: 100%;
  color: #32325d;
  background-color: white;
  border-bottom: 2px solid #B3B9C4;
  font-weight: lighter;
  margin-bottom: 10px;


  &--invalid {
    border-color: $badge-danger;
  }

  &--focus {
    border-color: $input-border-focus;
  }

  &--webkit-autofill {
    background-color: $lightest-grey-color !important;
  }

}

.new-payment-card-section {
  width: 420px;
  padding: 40px;
  background: $white;

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__field {
    width: 100%;

    & + .new-payment-card-section__field {
      margin-left: 100px;
    }

    input.owner-name {
      padding: 10px 0;
    }
  }
}

.new-payment-card-form {
  .ant-btn {
    width: 140px;
  }
}
