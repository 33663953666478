@import '../../../../vars';

.pay-manually-modal {
  .wire-transfer-desc {
    color: $black;
  }

  .wire-transfer-grid {
    margin-top: 20px;
  }

  .radio-group {
    width: 100%;

    .card-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      > div {
        display: flex;
        flex-direction: column;
        padding-left: 20px;

        .card-name {
          color: $black;
          font-weight: bold;
        }

        .default {
          margin-left: 8px;
          background: $black;
          color: $white;
          padding: 4px 6px;
          border-radius: 4px;
        }
      }
    }

    .card-wrapper {
      :global .ant-radio {
        margin-bottom: 10px;
      }
    }

    .another-card {
      display: inline-block;
      height: 65px;
      width: 162px;
      padding: 20px;
      font-size: 16px;
      margin-right: 12px;
      margin-bottom: 12px;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
    }
  }

  :global {
    .ant-modal {
      min-width: 630px;

      .ant-modal-title {
        line-height: unset;
      }

      .ant-modal-content {
        border-radius: 5px;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);

        .ant-modal-body {
          .ant-tabs-top-bar {
            margin-bottom: 40px;
          }

          p {
            color: $black;
            font-size: 16px;
            margin-top: 8px;
            margin-bottom: 0;
            line-height: 166.64%;
          }

          .ant-tabs-tabpane {
            // Fixed disappearance of tabs for safari
            -webkit-backface-visibility: hidden;
          }

          .ant-list-item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }

          .ant-list-footer {
            padding: 0;
          }
        }
      }
    }

    .ant-modal-footer {
      padding: 0 40px 40px;
      text-align: left;

      button {
        line-height: 22px;
        letter-spacing: -0.4px;
      }
    }
  }
}
