// Variables
$border-color: #CCD0E2;
$primary-color: #222222;
$light-primary-color: #232323;
$disabled-color: #CAF1FB;
$body-color: #636B79;
$danger-color: #ba2121;
$line-color: #E5E5EB;
$black: #000;
$white: #fff;
$dark: #001529;

$dark-grey-color: #566382;
$light-grey-color: #B5B5C0;
$lightest-grey-color: #F9F9F9;

$badge-default: #B3B9C4;
$badge-success: #00C24E;
$badge-danger: #E84060;
$badge-warning: #F2994A;
$badge-info: #20AFFF;

$input-border-focus: black;

$footer-height: 70px;
