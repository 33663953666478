@import '../../../../../vars';

.currencies-table {
  table {
    width: 100%;
    margin-bottom: 40px;

    th {
      padding: 20px 0 12px;
      border-bottom: 1px solid #efefef;
      color: $body-color;
      font-weight: normal;
      text-align: right;

      width: 15%;
      &:nth-of-type(1) {
        text-align: left;
        width: 25%;
      }
    }

    td {
      color: $black;
      padding: 20px 0;
      text-align: right;

      &:nth-of-type(1) {
        text-align: left;
      }
    }

    th:last-of-type {
      width: 8%;
    }

    tbody {
      tr:last-of-type {
        border-top: 1px solid #efefef;
      }
    }
  }
}

.estimation {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    margin-right: 8px;
    color: $body-color;
  }

  &__tooltip {
    max-width: 300px;
    font-size: 12px;

    a {
      color: unset;
      text-decoration: underline;
    }
  }
}
