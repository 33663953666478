@import '../../../../vars';

.app-card {
  background: $white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
  transition: all ease-in-out 150ms;

  width: 100%;
  height: 180px;
  padding: 22px;
  border-radius: 10px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  &.allow-click {
    cursor: pointer;

    &:hover {
      box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15);
      transform: translateY(-2px);
    }
  }

  h2 {
    width: 180px;
    margin-bottom: 0;
    margin-top: 12px;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  p {
    line-height: 166.64%;
    font-size: 12px;
    margin: 0;
  }

  .logo {
    height: 72px;
    width: 72px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
}
