@import '../../../../../../vars';

.overview {
  width: 690px;

  .content-wrapper {
    .title {
      margin-bottom: 24px;
    }

    .content {
      color: $dark;
      font-size: 16px;
      margin-top: 5px;
      line-height: 27px;
    }
  }

  .further-steps {
    .title {
      margin-bottom: 40px;
    }

    .further-step {
      display: flex;
      margin-bottom: 24px;

      p {
        margin: 0 0 4px;
      }

      .count {
        font-size: 16px;
        margin-right: 12px;
        line-height: 166.64%;
      }

      .content {
        .step-title {
          color: $black;
          font-size: 16px;
          line-height: 166.64%;
        }

        .step-description {
          font-size: 12px;
          line-height: 166.64%;
        }
      }
    }
  }
}
