@import '../../../../vars';

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 340px;
  height: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    min-height: min-content;
    padding-bottom: 30%;
  }

  &__forget {
    text-align: left;
    margin-bottom: 20px;
    text-decoration: underline;

    > a {
      color: $body-color;
    }
  }

  &__errors {
    text-align: center;
    color: $danger-color;
    margin-bottom: 20px;
  }

  &__footer {
    padding-top: 40px;
  }
}
