@import '../../../../../../../../vars';

.loading-placeholder {
  height: 100%;
  width: 100%;

  color: $black;
  font-size: 12px;
  border-radius: 8px;
  background: $lightest-grey-color;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
