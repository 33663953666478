.mini-apps {
  &__search {
    width: 340px;
    margin-right: 30px;
    margin-bottom: 40px;
  }
}

.app-details {
  .cards-row {
    margin-top: 40px;
    padding: 15px;
  }

  .no-active-miniapp {
    margin-top: 20px;
    text-align: center;
  }
}

.integrated-miniapps {
  margin: 80px 0;
}
