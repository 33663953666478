@import "../../../../vars";

.partnership-analytics {
  margin-top: 30px;
}

.date-picker-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 40px 0 20px 0;

  .separator {
    fill: $border-color;
  }

  .date-picker {
    margin-left: -11px;

    :global {
      .ant-picker-range-separator {
        padding: 0 20px;
      }

      .ant-picker-input {
        position: relative;

        > input {
          color: $black;
        }

        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -3px;

          width: 100%;
          height: 1px;
          background: $border-color;
        }
      }
    }
  }
}
