.nodata-placeholder {
  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    font-size: 16px;
    margin-top: 28px;
  }
}
