@import "../../../../../../vars";

.screenshots-uploader {
  .common-wrapper {
    height: 320px;
    overflow: hidden;
    border-radius: 8px;
    border: 0.5px solid $line-color;
    background: $lightest-grey-color;
  }

  .preview-area {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, 180px);

    .upload-area {
      height: 320px;

      color: $black;
      font-size: 12px;
      cursor: pointer;

      &__loading {
        cursor: auto;
      }

      .upload-btn {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        width: 100%;
        height: 100%;

        .icon {
          margin-bottom: 11px;
          font-size: 10px;
        }
      }
    }
  }

  &__landscape {
    .common-wrapper {
      height: 180px;
    }

    .preview-area {
      grid-template-columns: repeat(auto-fill, 320px);

      .upload-area {
        height: 180px;
      }
    }
  }
}
