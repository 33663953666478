@import '../../../../../../../vars';

.commercial-model {
  &__hidden {
    display: flex;
    justify-content: space-between;
    padding: 22px;
    background: $lightest-grey-color;

    .request-sent {
      color: $light-primary-color;
    }

    button {
      font-size: 16px;
    }
  }

  &__item {
    display: flex;

    &:before {
      display: flex;
      align-items: center;
      font-size: 12px;
      margin-right: 20px;
      content: '•';
      color: $badge-default;
    }

    &:not(:first-of-type) {
      margin-top: 4px;
    }
  }
}
