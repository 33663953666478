@import '../../../../../../../vars';

.preview-modal {
  position: relative;
  user-select: none;
  width: 360px;
  height: 640px;

  :global {
    .slick-list {
      height: 640px;

      .slick-track {
        height: 640px;
      }
    }
  }

  .carousel-item {
    border-radius: 8px;
    overflow: hidden;
    width: 360px;
    height: 640px;

    // Need for safari
    .img {
      width: 100%;
      height: 100%;

      img {
        border-radius: 8px;
        overflow: hidden;
      }
    }
  }

  .arrow {
    width: 56px;
    height: 56px;

    cursor: pointer;
    user-select: none;
    border-radius: 50%;
    background: $white;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    z-index: 1;

    &:hover {
      background-color: $line-color;
    }
  }

  .next-arrow {
    top: 50%;
    left: 100%;
    transform: translate(60px, -50%);
  }

  .prev-arrow {
    top: 50%;
    right: 100%;
    transform: translate(-60px, -50%);
  }

  &__landscape {
    width: 640px;
    height: 360px;

    :global {
      .slick-list {
        height: 360px;

        .slick-track {
          height: 360px;
        }
      }
    }

    .carousel-item {
      width: 640px;
      height: 360px;
    }
  }

  :global {
    .ant-carousel {
      overflow: hidden;
      font-size: 0;
      height: auto;

      border: 0.5px solid $line-color;
      border-radius: 8px;
    }
  }
}

