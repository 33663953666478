@import '../../../../vars';

.page-footer {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding: 20px 30px;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      margin-left: 10px;
      color: $dark-grey-color;

      a {
        color: $dark-grey-color;
      }
    }
  }
}
