@import '../../../../vars';


.launches-payment-table {
  width: 100%;
  margin-bottom: 40px;

  th {
    padding: 20px 0 12px;
    border-bottom: 1px solid $border-color;
    color: $body-color;
    text-align: right;
    width: 15%;


    &:nth-child(1) {
      text-align: left;
      width: 55%;
    }
  }


  tbody > tr {
    border-radius: 10px;

    td {
      vertical-align: top;
      text-align: right;

      &:first-child {
        color: $black;
        padding-left: 10px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        text-align: left;
      }
      &:nth-child(2) {
        color: $black;
      }
      &:last-child {
        color: $black;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        text-align: right;
      }
    }

    &:first-of-type {
      td {
        padding: 20px 0;
      }
      border-bottom: 1px solid $border-color;
    }
    &:nth-of-type(2) {
      td {
        padding-top: 14px;
      }
    }
  }
}

