@import '../../../../vars';

.country-modal {
  &__content-wrapper {
    display: grid;
    grid-row-gap: 40px;
    grid-template-rows: 80px 400px 42px;

    .header {
      h1 {
        margin: 0 0 8px;
        font-size: 32px;
        line-height: 44px;
        letter-spacing: -0.03em;
      }

      p {
        color: #636B79;
        font-size: 16px;
        line-height: 166.64%;
      }
    }

    main {
      border: 1px solid #E5E5EB;
      border-radius: 5px;
      padding: 5px;

      .scroll-wrapper {
        overflow-y: scroll;
        height: 100%;

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 4px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: #E5E5EB;
        }

        .worldwide-collapse-item {
          background: #fff;
          padding: 20px 0;
          margin: 0 11px 0 20px;
          border-bottom: 1px solid #E5E5EB;

          .checkbox {
            display: flex;
            align-items: center;
            width: min-content;

            h3 {
              margin: 0 0 0 12px;
              font-weight: 900;
              font-size: 16px;
              line-height: 166.64%;
            }
          }
        }

        .collapse {
          background: $white;

          .expand-arrow {
            transform: rotate(90deg) !important;
            transition: transform .2s;

            &.active {
              transform: rotate(-90deg) !important;
            }
          }

          :global {
            .ant-collapse-item {
              border-bottom: 1px solid #E5E5EB !important;

              &:last-child {
                border-bottom: none !important;
              }
            }
          }

          .panel {
            background: $white;
            margin: 0 11px 0 20px;

            :global {
              .ant-collapse-content-box {
                padding: 0 16px 16px;
              }

              .ant-collapse-header {
                padding: 0 !important;

                svg {
                  font-size: 13px;
                  fill: #CCD0E2;
                }
              }
            }

            .modal-header {
              background: #fff;
              padding: 20px 0;
              width: min-content;

              .checkbox {
                display: flex;
                align-items: center;
                width: max-content;

                h3 {
                  margin: 0 0 0 12px;
                  font-weight: 900;
                  font-size: 16px;
                  line-height: 166.64%;
                }
              }
            }

            &__checkbox-group {
              display: grid;
              grid-template-columns: repeat(3, 1fr);

              span {
                font-size: 12px;
                line-height: 166.64%;
                color: black;
              }

              :global {
                .ant-checkbox-group-item {
                  display: flex;
                  align-items: center;
                  margin-bottom: 12px;
                }
              }
            }
          }
        }
      }
    }

    footer {
      display: grid;
      grid-column-gap: 20px;
      grid-template-columns: 112px 112px;
    }
  }
}
