.main-pane-layout {
  &__body {
    margin-top: 40px;
  }
}

.header-wrapper {
  display: flex;
}

.tooltip {
  margin-left: 16px;
  margin-top: 12px;
}
