@import '../../../../vars';


.currency-data-tooltip {
  max-width: 300px;

  &__header {
    color: $black;
    font-size: 18px;
  }

  hr {
    background: $line-color;
    border: none;
    height: 1px;
  }

  a {
    color: unset;
    text-decoration: underline;
  }

  p {
    font-size: 12px;
  }
}
