.not-found {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;

  background: linear-gradient(176.83deg, #FFFFFF 2.62%, #F4F5F7 27.5%, #F4F5F8 52.47%, #EAECF1 97.38%);

  &__header {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: space-evenly;
  }

  &__help-text {
    text-align: center;
  }

  &__logo {
    display: flex;
    justify-content: center;

    margin-top: 60px;

    svg {
      height: 70px;
    }
  }

  &__home-btn {
    margin: 0 auto;
    width: 340px;
  }

  &__icon {
    width: 35%;

    > svg {
      display: block;
    }
  }

  @media only screen and (max-device-width: 480px) {
    overflow: hidden;

    h1 {
      font-size: 22px;
    }

    span {
      font-size: 16px;
    }

    &__home-btn {
      width: 80%;
    }

    &__icon {
      width: 110%;
    }
  }
}
