@import '../../../../../../vars';

.documents-list {
  :global .ant-list-item {
    padding-left: 0;
    padding-right: 0;
  }
  .document {
    display: flex;

    span {
      align-items: center;
      font-size: 16px;
      margin-right: 10px;
    }
  }
}
