.payouts-settings {
  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__sub {
    padding-bottom: 32px;
  }
  &__content {
    padding-top: 40px;
  }

  .single-page-layout__content {
    padding-top: 10px;
  }
}
