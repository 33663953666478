.miniapp-documents {
  margin-top: 50px;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__item {
    span {
      font-size: 16px;
      margin-right: 10px;
    }
  }

  .ant-list-lg .ant-list-item {
    padding-left: 0;
    padding-right: 0;
  }

  .ant-upload .ant-btn-loading {
    padding-left: 46px !important;
  }
}
