.auth-layout {
  max-width: 425px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  padding: 60px 0 40px 0;

  &__back {
    margin-left: 30px;
    margin-top: 40px;
    position: absolute;
    width: 48px;
    height: 48px;
    cursor: pointer;

    svg {
      position: absolute;
      width: 18px;
      top: 50%;
      left: 50%;
      height: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__header {
    text-align: center;
    padding-bottom: 40px;
  }

  &__logo {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    svg {
      height: 70px;
    }
  }

  &__subtitle {
    font-size: 16px;
    padding-top: 4px;
    text-align: center;
  }
}

.login-layout {
  max-width: 340px;
  margin: 0 auto;
  display: grid;
  grid-gap: 60px;
  height: 100vh;
  padding: 130px 0 100px;
  grid-template-rows: 181px 1fr;

  h1 {
    text-align: center;
  }

  &__logo {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;

    svg {
      height: 70px;
    }

    @media screen and (min-height: 600px) {
      margin-bottom: 56px;
    }
  }
}
