@import '../../../../../../../vars';

.screenshot-preview {
  position: relative;

  &__allowed {
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 3;

      transition: background-color .4s;

      background-color: rgba(0, 0, 0, 0);
    }

    &:hover {
      &:before {
        background-color: rgba(0, 0, 0, .4);
      }

      .actions {
        .action {
          &:first-child, &:last-child {
            transform: translateX(0);
          }
        }
      }
    }
  }

  &.blackout {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 3;

      transition: background-color .4s;

      background-color: rgba(0, 0, 0, .4);
    }
  }

  .actions {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 4;
    transform: translate(-50%, -50%);

    display: flex;

    .action {
      width: 44px;
      height: 44px;
      margin: 0 6px;
      border-radius: 8px;

      transition: transform .5s;
      background-color: $white;

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: $line-color;
      }

      &:first-child {
        transform: translateX(-400px);
      }

      &:last-child {
        transform: translateX(400px);
      }

      .icon {
        color: $black;
      }
    }
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 4;
    transform: translate(-50%, -50%);
  }

  // Need for safari
  .img {
    width: 100%;
    height: 100%;

    img {
      border-radius: 8px;
      overflow: hidden;
    }
  }

}

:global {
  .action-tooltip {
    pointer-events: none;
    z-index: 3;

    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-inner {
      display: flex;
      align-items: center;

      padding: 4px 12px;
      border-radius: 14px;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);

      color: $dark;
      font-size: 12px;
      background: $white;
      line-height: 166.64%;
    }
  }
}
