@import '../../../../../../vars';

.partner-integration {
  font-size: 16px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__section {
    &:not(:first-of-type) {
      margin-top: 80px;
      width: 800px;
    }

    .inherit-colors {
      display: flex;
    }

    .label-tooltip {
      display: inline-flex;
      align-items: center;
      width: 280px;

      svg {
        margin-left: 13px;
      }
    }

    .required-fields {
      display: inline-flex;
      border-bottom: 0.75px solid #CCD0E2;
      width: 400px;
      vertical-align: bottom;

      > span {
        padding: 2px 8px;
        background: #F9F9F9;
        margin-bottom: 5px;

        &:not(:first-of-type) {
          margin-left: 12px;
        }
      }
    }

    .required-fields-snippet {
      font-size: .85em;
      padding: 20px 24px;
      border-radius: 6px;
      background: $lightest-grey-color;
      margin-top: 30px;

      pre {
        margin-bottom: 0;
      }

      .punctuation, .operator {
        color: $badge-default;
      }
      .property {
        color: $body-color;
      }
      .string {
        color: $badge-warning;
      }
    }
  }

  :global(.ant-form-item-no-colon)  {
    width: 280px;
  }
}
