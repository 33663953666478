@import '../../../../vars';


.transaction-commission-table {
  width: 100%;
  margin-bottom: 40px;

  th {
    padding: 20px 0 12px;
    border-bottom: 1px solid $border-color;
    color: $body-color;
    text-align: right;
    width: 15%;


    &:nth-child(1) {
      text-align: left;
      width: 25%;
    }
  }


  tbody > tr {
    border-radius: 10px;

    td {
      padding: 20px 0;
      vertical-align: top;
      text-align: right;

      &:first-child {
        color: $black;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        text-align: left;
      }
      &:nth-child(2) {
        color: $black;
      }
      &:last-child {
        color: $black;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        text-align: right;
      }
    }

    &:last-of-type {
      td {
        border-top: 1px solid $border-color;
        padding-top: 14px;
      }
    }
  }
}

.estimation {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    margin-right: 8px;
    color: $body-color;
  }

  &__tooltip {
    max-width: 300px;
    font-size: 12px;

    a {
      color: unset;
      text-decoration: underline;
    }
  }
}

