@import "../../../../vars";

.partnerships-card {
  height: 180px;
  display: flex;
  cursor: pointer;
  border-radius: 10px;
  justify-content: space-between;
  transition: ease-in-out 150ms;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15);
  }

  &__part {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    h2 {
      width: 180px;
      margin-bottom: 0;
      margin-top: 12px;
      overflow: hidden;
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    p {
      line-height: 166.64%;
      font-size: 12px;
      margin: 0;
    }

    .logo {
      border-radius: 10px;
      overflow: hidden;
      height: 72px;
      width: 72px;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }

  .divider {
    width: 1px;
    height: 100%;
    background: $line-color;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
}

:global {
  .partnership-card-tooltip {
    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-inner {
      display: flex;
      align-items: center;

      padding: 4px 12px;
      border-radius: 14px;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);

      color: $dark;
      font-size: 12px;
      background: $white;
      line-height: 166.64%;
    }
  }

  .partnership-name-card-tooltip {
    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-inner {
      display: flex;
      align-items: center;

      max-width: 180px;
      padding: 4px 12px;
      border-radius: 4px;
      border: 1px solid $line-color;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);

      color: $dark;
      font-size: 12px;
      line-height: 166.64%;
      background: $lightest-grey-color;
    }
  }
}
