@import '../../../../vars';

.double-logo {
  height: 72px;
  width: 72px;

  div {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 48px;
    height: 48px;
    overflow: hidden;
    background: $white;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);

    &:last-child {
      transform: translate(50%, -50%);
    }

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: cover;
    }
  }
}
