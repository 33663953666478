@import '../../../../../../vars';

.overview {

  .title {
    margin-bottom: 24px;
  }

  .list-item {
    margin-bottom: 24px;

    span {
      font-size: 12px;
      line-height: 20px;
    }

    div {
      line-height: 27px;
      font-size: 16px;
      color: $dark;
    }
  }
}
