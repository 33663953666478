.content {
  :global(.ant-btn) {
    padding: 10px 32px;
  }

  :global(.ant-btn-default) {
    border: 1px solid #222;
  }
}

.description {
  color: #000;
}