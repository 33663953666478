@import '../../../../vars';

.app-internal-analytics {
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;
      align-items: center;

      .title {
        margin: 0 20px 0;

        i {
          color: $line-color;
        }
      }
    }

    margin-bottom: 40px;
  }

  .date-picker-wrapper {
    .separator {
      fill: $border-color;
    }

    .date-picker {
      margin-left: -11px;

      :global {
        .ant-picker-range-separator {
          padding: 0 20px;
        }

        .ant-picker-input {
          position: relative;

          > input {
            color: $black;
          }

          &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -3px;

            width: 100%;
            height: 1px;
            background: $border-color;
          }
        }
      }
    }
  }
}
