@import '../../../../../../../vars';

.details-carousel {
  position: relative;
  user-select: none;
  width: 100%;
  height: 320px;

  :global {
    .ant-carousel {
      border: none;

      .slick-list {
        height: 320px;

        .slick-track {
          height: 320px;

          .slick-slide {
            &:last-child {
              > div {
                > div {
                  padding-right: 0;
                  width: 180px !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .carousel-item {
    height: 320px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 8px;
    padding-right: 20px;
    width: 200px !important;

    // Need for safari
    .img {
      width: 100%;
      height: 100%;

      img {
        border: 0.5px solid $line-color;
        border-radius: 8px;
        overflow: hidden;
      }
    }
  }

  .arrow {
    width: 44px;
    height: 44px;

    cursor: pointer;
    user-select: none;
    border-radius: 50%;
    background: $white;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    z-index: 1;

    &:hover {
      background-color: $line-color;
    }
  }

  .next-arrow {
    top: 50%;
    left: 100%;
    opacity: 0;
    transition: .5s;
    transform: translate(50%, -50%);

    &__visible {
      opacity: 1;
      transform: translate(calc(-100% - 6px), -50%);
    }
  }

  .prev-arrow {
    top: 50%;
    right: 100%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: .5s;

    &__visible {
      opacity: 1;
      transform: translate(50%, -50%);
    }
  }

  &__landscape {
    height: 180px;

    .next-arrow {
      transform: translate(50%, -50%);

      &__visible {
        transform: translate(-100%, -50%);
      }
    }

    .carousel-item {
      width: 340px !important;
      height: 180px;
    }

    :global {
      .ant-carousel {
        border: none;

        .slick-list {
          height: 180px;

          .slick-track {
            height: 180px;

            .slick-slide {
              &:last-child {
                > div {
                  > div {
                    padding-right: 0;
                    width: 320px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .blurred-edge {
    position: absolute;
    top: 0;
    width: 56px;
    height: 320px;
    transition: .4s;

    &__right {
      left: 634px;
      pointer-events: none;
      transform: translateX(100%);

      &--active {
        transform: translateX(0);
        background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);

        &-landscape {
          background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 10%);
        }
      }
    }

    &__left {
      left: 0;
      pointer-events: none;
      transform: translateX(-100%);

      &--active {
        transform: translateX(0);
        background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      }
    }
  }
}
