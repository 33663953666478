@import '../../../../../vars';

.color-picker {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__l {
    display: flex;
    align-items: center;
  }

  &__r {
    width: 30%;
    position: relative;

    :global .ant-input {
      padding-left: 30px;
      border: 1px solid #CCD0E2;
      border-radius: 6px;
      line-height: 22px;

      &:focus,
      &:hover {
        border-right-width: 1px !important;
        border-color: $input-border-focus;
      }
    }
  }

  &__label {
    font-size: 16px;
  }

  &__trigger {
    position: absolute;
    height: 18px;
    width: 18px;
    top: 8px;
    left: 6px;
    z-index: 1;
    border-radius: 4px;
    cursor: pointer;
  }

  &__popover {
    position: absolute;
    z-index: 2;
    bottom: 110%;
  }

  &__cover {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
