@import "../../../../vars";

.team-table {
  .ant-table-thead {
    th {
      padding: 20px 0 12px;
      border-bottom: 1px solid $border-color;
      color: $body-color;
    }
  }
  .ant-table-tbody {
    > tr.ant-table-row:hover > td {
      background: unset;
    }

    td {
      &:last-child {
        text-align: right;
        padding-right: 10px;
      }

      vertical-align: top;
      padding: 20px 0;
    }
  }

  &__title {
    font-weight: bold;
    padding-bottom: 4px;
    color: $black;
  }

  &__status {
    display: flex;
    align-items: center;
  }

  .ant-table-thead {
    .ant-table-cell {
      background: transparent;
    }
  }
}
