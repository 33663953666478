@import '../../../../vars';

.single-page-layout {
  position: relative;
  padding: 40px 40px $footer-height 40px;
  min-height: 100vh;

  &.is-full-height {
    height: 100vh;
  }

  &__content {
    padding: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    max-width: 1070px;

    &.padding-lg {
      padding: 64px 0;
    }
  }

  &__close,
  &__back {
    position: absolute;
    width: 48px;
    height: 48px;
    cursor: pointer;

    svg {
      position: absolute;
      width: 18px;
      height: 18px;
      top: 50%;
      left: 50%;
      height: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__close {
    right: 30px;
  }

  &__back {
    left: 30px;

    @media screen and (max-width: 1000px) {
      left: 0;
      top: 35px;
    }
  }
}
