.two-factor-auth-step {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 470px;
  margin: auto;
  text-align: center;
  height: 100%;

  > div, form {
    width: 340px;
    margin: 0 auto;
  }
}
