@import '../../../../vars';

.showroom-category {

  .title {
    display: flex;
    align-items: baseline;

    h2 {
      font-size: 22px;
      margin: 0 12px 0 0;
      letter-spacing: -0.03em;
    }

    .link {
      font-size: 16px;
      cursor: pointer;
      font-weight: bold;
      color: $primary-color;
    }
  }

  .empty {
    margin-top: 28px;
  }

  .cards {
    margin-top: 20px;

    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(auto-fill, 320px);
  }

  .carousel-wrapper {
    position: relative;

    .arrow {
      width: 44px;
      height: 44px;

      cursor: pointer;
      user-select: none;
      border-radius: 50%;
      background: $white;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);

      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      z-index: 1;

      &:hover {
        background-color: $line-color;
      }
    }

    .next-arrow {
      top: 50%;
      left: 100%;
      opacity: 0;
      transition: .5s;
      transform: translate(50%, -50%);

      &__visible {
        opacity: 1;
        transform: translate(-20%, -50%);
      }
    }

    .prev-arrow {
      top: 50%;
      right: 100%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: .5s;

      &__visible {
        opacity: 1;
        transform: translate(50%, -50%);
      }
    }

    .carousel {
      margin-bottom: 60px;
      margin-left: -20px;

      .card {
        width: 360px !important;
        padding: 20px;
      }

      :global {
        .slick-list {
          margin-right: -30px !important;
        }
      }
    }
  }
}
