.activate-account-modal {
  :global .ant-modal-content {
    .ant-modal-body {
      height: 70px;
      padding-top: 0;
    }

    .ant-modal-footer {
      padding: 40px;
      text-align: left;
    }
  }
}

.help-text {
  padding-right: 10px;
}
