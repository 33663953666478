@import '../../../../vars';

.skeleton {
  display: grid;
  align-items: center;
  grid-template-rows: 298px 145px 1px 71px;

  padding: 0 20px;
  background: $white;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);

  .divider {
    background: $line-color;
    margin: 0;
  }

  :global {
    .ant-skeleton-element {
      width: max-content;
      border-radius: 4px;
      overflow: hidden;
    }
  }
}
