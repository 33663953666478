.modal-content {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.footer {
  display: flex;
  gap: 20px;
}

.title {
  color: #000;
  font-size: 22px;
}

.desc {
  font-size: 14px;
}
