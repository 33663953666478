.loader {
  &__screenshots {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(3, 180px);

    margin-bottom: 40px;
  }

  :global {
    .ant-skeleton-element {
      width: max-content;
      border-radius: 4px;
      overflow: hidden;
    }
  }
}
