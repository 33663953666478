@import '../../../../vars';


.payment-table {
  width: 100%;
  margin-bottom: 40px;

  th {
    padding: 20px 0 12px;
    border-bottom: 1px solid $border-color;
    color: $body-color;
    width: 25%;

    &:nth-child(3) {
      width: 30%;
    }

    &:nth-child(4) {
      width: 20%;
      text-align: right;
    }
  }


  tbody > tr {
    border-radius: 10px;

    &.clickable:hover {
      cursor: pointer;
      background: $lightest-grey-color;
      border-radius: 10px;
    }

    td {
      padding: 20px 0;
      vertical-align: top;

      &:nth-child(1), &:nth-child(2) {
        color: $black;
      }

      &:first-child {
        padding-left: 10px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      &:last-child {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
        padding-right: 10px;
        text-align: right;
      }
    }

    .app-info {
      img {
        width: 36px;
        height: 36px;
        border-radius: 8px;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.08);
        margin-right: 12px;
      }
      div {
        width: 70%;
        display: inline-block;
        vertical-align: middle;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .details {
      color: $black;
      margin-bottom: 5px;
    }

    .amount {
      color: $black;
      margin-bottom: 5px;
      font-size: 18px;
    }
  }
}

.currency-data-tooltip {
  max-width: 300px;

  &__header {
    color: $black;
    font-size: 18px;
  }

  hr {
    background: $line-color;
    border: none;
    height: 1px;
  }

  a {
    color: unset;
    text-decoration: underline;
  }

  p {
    font-size: 12px;
  }
}
