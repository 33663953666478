@import '../../../../../vars';

.app-id-header {
  width: 70%;

  &__title {
    margin-bottom: 12px;
  }

  .app-id {
    background: #F9F9F9;
    padding: 22px 20px;
    display: flex;
    justify-content: space-between;

    &__description, &__content {
      font-size: 16px;
      line-height: 166.64%;
    }

    &__description {
      color: $body-color;
    }

    &__content {
      cursor: pointer;
      font-weight: bold;
      fill: $primary-color;
      color: $primary-color;

      display: flex;
      align-items: center;

      span {
        margin-right: 10px;
      }
    }
  }
}
