@import '../../../../../vars';


.integration-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 340px;
  height: 100%;
  margin: 0 auto;

  &__header {
    text-align: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding-bottom: 10%;
  }

  &__footer {
    padding-top: 40px;
  }

  &__superapp-select {
    width: 100%;
    text-align: left;
  }

  &__checkbox {
    display: flex;
    align-items: center;
    text-align: left;
    :global .ant-checkbox {
      margin-right: 8px;
    }

    span {
      font-size: 12px;

      a {
        color: unset;
        text-decoration: underline;
      }
    }
  }
}
