.info-card {
  width: 100%;
  height: 280px;
  cursor: pointer;
  transition: all ease-in-out 150ms;

  &:hover {
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15);
    transform: translateY(-5px);
  }

  :global {
    .ant-btn {
      position: absolute;
      left: 24px;
      bottom: 24px;
    }
  }
}
